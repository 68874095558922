.toast {
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  box-shadow: 0 8 8 #10182808;

  .Toastify__toast-icon {
    display: flex;
    align-self: self-start;
    justify-self: flex-start;
  }
}

.toast.success {
  background-color: #e4e4e7;
  color: #3f3f46;
}

.toast.error {
  background-color: #e4e4e7;
  color: #3f3f46;
}

.toast.info {
  background-color: #e4e4e7;
  color: #3f3f46;
}

.toast.warning {
  background-color: #e4e4e7;
  color: #3f3f46;
}

.toast.default {
  background-color: #e4e4e7;
  color: #3f3f46;
}

.toast.dark {
  background-color: #ffffff;
  color: #fafafa;
}
